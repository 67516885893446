import { TFunction } from "i18next";
import Image from "next/image";
import React from "react";
import { useTranslation } from "react-i18next";
import LanguageBadge from "src/components/LanguageBadge";
import { BriefStatus } from "src/enums";
import { Text } from "src/uikit";
import styled from "styled-components";

import { IMediaResource, ITagResource, IUserResource } from "@sol/sdk";

import UserBox from "../shared/UserBox";
import { BlockSkeleton, TextSkeleton } from "../skeletons";

const Banner = styled.div`
    width: 100%;
    height: 410px;
    // As next/image add intermediate
    // markup with position absolute
    position: relative;
    display: flex;

    > * {
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }
`;

const Content = styled.div`
    padding: ${({ theme }) => theme.spacing[7]};
`;

const Tag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: 40px;
    --text-color: ${({ theme }) => theme.palette.grey.base};
`;

const TagsContainer = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: ${({ theme }) => theme.spacing[5]} 0 0;
    list-style-type: none;

    gap: ${({ theme }) => theme.spacing[2]};
`;

const BadgesContainer = styled.div`
    margin: ${({ theme }) => theme.spacing[5]} 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ImageSkeleton = styled(BlockSkeleton)``;

const Pill = styled(Text)`
    background-color: ${({ theme }) => theme.palette.purple.lighter2};
    --text-color: ${({ theme }) => theme.palette.purple.base};
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
    width: max-content;
    border-radius: 40px;
    border: 1px solid ${({ theme }) => theme.palette.purple.base};
`;

const getBriefStatus = (t: TFunction, status?: BriefStatus, classrooms?: string[]) => {
    if (status === BriefStatus.VALIDATED) {
        return (classrooms?.length || 0) > 0
            ? t("page.briefs.create.general.assigned")
            : t("page.briefs.create.general.notAssigned");
    } else if (status === BriefStatus.DRAFT) {
        return t("page.briefs.create.general.draft");
    } else {
        return t("page.briefs.create.general.notAssigned");
    }
};

const PICTURE_PLACEHOLDER = "/static/placeholders/picture_placeholder_purple.svg";

type Props = {
    title?: string;
    image?: IMediaResource;
    description?: string;
    locale?: string;
    createdBy?: IUserResource;
    createdAt?: string;
    tags?: ITagResource[];
    status?: BriefStatus;
    classrooms?: string[];
    isLoading?: boolean;
    className?: string;
};

const BriefSummaryHeaderCard = ({
    title,
    className,
    image,
    description,
    tags,
    status,
    classrooms,
    createdAt,
    createdBy,
    locale,
    isLoading = false,
}: Props) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <Banner>
                {isLoading ? (
                    <ImageSkeleton />
                ) : (
                    <Image
                        src={image?.url || PICTURE_PLACEHOLDER}
                        alt=""
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                    />
                )}
            </Banner>
            <Content>
                <Text as="h1" variant="h3" data-cy="brief-summary-title">
                    {isLoading ? <TextSkeleton height="32px" width="60%" /> : title}
                </Text>
                <BadgesContainer>
                    <Pill variant="subheading" as="p" data-cy="brief-summary-status">
                        {getBriefStatus(t, status, classrooms)}
                    </Pill>
                    {locale && <LanguageBadge locale={locale} />}
                </BadgesContainer>

                {createdBy && createdAt && <UserBox user={createdBy} date={createdAt} type="createdAt" />}

                {isLoading ? (
                    <>
                        <TextSkeleton width="90%" />
                        <TextSkeleton />
                        <TextSkeleton width="70%" />
                    </>
                ) : (
                    <Text data-cy="brief-summary-description" preserve>
                        {description}
                    </Text>
                )}
                {tags && tags.length > 0 && (
                    <TagsContainer data-cy="brief-summary-tags">
                        {tags.map(tag => (
                            <li key={tag["@id"]}>
                                <Tag>
                                    <Text variant="subheading" as="span">
                                        {tag.title}
                                    </Text>
                                </Tag>
                            </li>
                        ))}
                    </TagsContainer>
                )}
            </Content>
        </div>
    );
};

export default styled(BriefSummaryHeaderCard)`
    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    ${UserBox} {
        margin: ${({ theme }) => theme.spacing[5]} 0;
    }
`;
