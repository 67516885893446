/* eslint-disable react/display-name */
import dynamic from "next/dynamic";
import React, { memo, useMemo } from "react";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Anchor, Blockquote, Code, OrderedList, UnorderedList } from "../typography";
import MarkdownConverter from "./MarkdownConverter";

const Codemirror = dynamic(() => import("../Codemirror/Codemirror"), {
    ssr: false,
});

const trimCode = str => {
    let code = `${str}`;
    code.trim();
    code = code.replace(/(^\{`)|((\s)*`\}$)/g, "");
    code.trim();
    return code;
};

const Image = styled.img`
    max-width: 100%;
`;

const defaultComponents = [
    {
        name: "h1",
        component: props => <Text {...props} variant="h1" as="h1" />,
    },
    {
        name: "h2",
        component: props => <Text {...props} variant="h2" />,
    },
    {
        name: "h3",
        component: props => <Text {...props} variant="h3" />,
    },
    {
        name: "label",
        component: props => <Text {...props} variant="label" />,
    },
    {
        name: "subheading",
        component: props => <Text {...props} variant="subheading" />,
    },
    {
        name: "p",
        component: props => <Text {...props} preserve />,
    },
    {
        name: "a",
        component: Anchor,
    },
    {
        name: "strong",
        component: "b",
    },
    {
        name: "em",
        component: "i",
    },
    {
        name: "u",
        component: "u",
    },
    {
        name: "blockquote",
        component: Blockquote,
    },
    /* eslint-disable react/display-name */
    {
        name: "code",
        component: ({ children, ...props }) => <Code {...props}>{trimCode(children[0])}</Code>,
    },
    {
        name: "pre",
        component: props => {
            const { children } = props;

            const { children: rawCode, className: language } = children[0].props;

            return (
                <Codemirror
                    readOnly
                    language={language && language.replace("language-", "")}
                    value={trimCode(rawCode)}
                />
            );
        },
    },
    /* eslint-enable react/display-name */
    {
        name: "ul",
        component: UnorderedList,
    },
    {
        name: "ol",
        component: OrderedList,
    },
    {
        name: "li",
        component: "li",
    },
    {
        name: "img",
        component: Image,
    },
];

const Markdown = props => {
    const { markdown, components = [], ...options } = props;

    const converter = useMemo(
        () =>
            new MarkdownConverter({
                ...options,
                components: [...defaultComponents, ...components],
            }),
        [options, components],
    );

    const content = useMemo(() => converter.convert(markdown || ""), [markdown, converter]);

    return <div>{content}</div>;
};

Markdown.displayName = "Markdown";

export default memo(Markdown);
