import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SROnly, Text } from "src/uikit";
import styled from "styled-components";

import { Chevron, Skill } from "@sol/icons";
import {
    IBrief,
    IFrameworkItem,
    ILanguageResource,
    IMediaResource,
    IProfessionalSituationResource,
    ITagResource,
    IUserResource,
    MediaType,
} from "@sol/sdk";
import { Tag } from "@sol/uikit";
import { IconButton } from "@sol/uikit/buttons";
import Button from "@sol/uikit/buttons/Button";
import { Grid, GridColumn } from "@sol/uikit/layouts/Grid";

import { BriefStatus } from "../../enums";
import useScroll from "../../hooks/useScroll";
import Markdown from "../Markdown";
import Attachment from "../shared/Attachment";
import { BlockSkeleton, TextSkeleton } from "../skeletons";
import { SkillLevelChipStatus } from "../SkillLevelChip";
import SkillValidation from "../SkillValidation";
import BriefSummaryCard from "./BriefSummaryCard";
import BriefSummaryHeaderCard from "./BriefSummaryHeaderCard";

const ProfessionalSituation = styled.div`
    margin-top: ${({ theme }) => theme.spacing[7]};
    padding: ${({ theme }) => theme.spacing[6]};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing[5]};
    background-color: ${({ theme }) => theme.palette.white.base};
    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    width: 100%;

    --icon-color: ${({ theme }) => theme.palette.purple.base};
`;

const Title = styled(Text)`
    --text-color: ${({ theme }) => theme.palette.purple.base};
`;

const Problematic = styled(Text)`
    color: ${({ theme }) => theme.palette.grey.base};
`;

const SkillsContainer = styled.ul`
    margin: 0;
    padding: 0;

    & > * + * {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

const FrameworkItem = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    border: ${({ theme }) => `1px solid ${theme.palette.grey.lightest}`};
    border-radius: 56px;
    text-align: center;
    padding: ${({ theme }) => theme.spacing[5]};
    list-style: none;
`;

const FrameworkItemSkeleton = styled(BlockSkeleton)`
    border-radius: 56px;
    width: 100%;
`;

const TextLoader = styled(({ className }: { className?: string }) => (
    <div className={className}>
        <TextSkeleton />
        <TextSkeleton width="90%" />
        <TextSkeleton width="80%" />
        <TextSkeleton width="80%" />
        <TextSkeleton width="60%" />
    </div>
))`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
`;

const SkillSkeleton = styled(BlockSkeleton)`
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    height: 66px;

    & + & {
        margin-top: ${({ theme }) => theme.spacing[3]};
    }
`;

const MoreSkillsActionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing[8]};

    ${IconButton} {
        margin-left: ${({ theme }) => theme.spacing[3]};

        &:hover {
            ${Chevron} {
                --icon-color: ${({ theme }) => theme.palette.white.base};
            }
        }
    }

    > ${Text} {
        margin-right: ${({ theme }) => theme.spacing[1]};
    }
`;

export type SelectClassroomOption = {
    label: string;
    value?: string;
};

export type BriefSkill = {
    skill: string;
    title: string;
    levels: {
        id: string;
        level: number;
        status?: SkillLevelChipStatus;
    }[];
};

export const formatBriefSkillLevelsToBriefSkill = (skillLevels: IBrief["skillLevels"]): BriefSkill[] =>
    skillLevels?.map(skillLevel => {
        return {
            skill: skillLevel.skill["@id"],
            title: skillLevel.skill.officialTitle,
            levels: [1, 2, 3].map(levelIndex => ({
                id: `${skillLevel["@id"]}-${levelIndex}`,
                level: levelIndex,
                status:
                    skillLevel.level >= levelIndex
                        ? SkillLevelChipStatus.VALIDATED
                        : SkillLevelChipStatus.NOT_EVALUATED,
            })),
        };
    });

const DEFAULT_SKILLS_TO_SHOW = 3;

type Props = {
    context?: string;
    description?: string;
    expectedWork?: string;
    pedagogicModalities?: string;
    title?: string;
    btnDraftAside?: React.ReactNode;
    classrooms?: string[];
    createdAt?: string;
    createdBy?: IUserResource;
    evaluationModalities?: string;
    image?: IMediaResource;
    media?: IMediaResource[];
    performanceCriteria?: string;
    professionalSituation?: IProfessionalSituationResource;
    displaySubmit?: boolean;
    selectedClassroom?: SelectClassroomOption;
    setSelectedClassroom?: (v?: SelectClassroomOption) => void;
    skills?: BriefSkill[];
    status?: BriefStatus;
    tags?: ITagResource[];
    className?: string;
    language?: ILanguageResource;
    frameworks?: IFrameworkItem[];
    isLoading?: boolean;
};

const BriefSummary = ({
    displaySubmit,
    title,
    description,
    pedagogicModalities,
    expectedWork,
    context,
    performanceCriteria,
    evaluationModalities,
    skills,
    tags,
    media,
    createdAt,
    createdBy,
    image,
    professionalSituation,
    btnDraftAside,
    classrooms,
    status,
    language,
    className,
    frameworks,
    isLoading = false,
}: Props) => {
    const scrollRef = useScroll();
    const [t] = useTranslation();
    const [showAllSkills, setShowAllSkills] = useState(false);

    useEffect(() => {
        if (scrollRef) {
            scrollRef.scrollTo(0, 0);
        }
    }, [scrollRef]);

    return (
        <div className={className} data-cy="brief-summary">
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-8 flex flex-col gap-4">
                    <BriefSummaryHeaderCard
                        title={title}
                        description={description}
                        image={image}
                        createdBy={createdBy}
                        createdAt={createdAt}
                        tags={tags}
                        status={status}
                        classrooms={classrooms}
                        locale={language?.locale}
                        isLoading={isLoading}
                    />
                    {frameworks && (
                        <BriefSummaryCard title={t("component.briefSummary.frameworks")}>
                            <Grid as="ul">
                                {isLoading
                                    ? [1, 2, 3].map(item => (
                                          <GridColumn as={FrameworkItemSkeleton} col={4} key={item} />
                                      ))
                                    : frameworks.map(framework => (
                                          <GridColumn
                                              col={4}
                                              as={FrameworkItem}
                                              key={framework["@id"]}
                                              data-cy="brief-summary-frameworks"
                                          >
                                              <Text lines={2} variant="subheading">
                                                  {framework.title}
                                              </Text>
                                          </GridColumn>
                                      ))}
                            </Grid>
                        </BriefSummaryCard>
                    )}
                    {media && media.length > 0 && (
                        <BriefSummaryCard title={t("component.briefSummary.media")}>
                            <Grid>
                                {media.map(resource => {
                                    const mediaKind = resource.type === MediaType.FILE ? "media" : "link";

                                    return (
                                        <GridColumn key={resource["@id"]} col={6}>
                                            <Attachment
                                                kind={mediaKind}
                                                title={resource.title}
                                                url={resource.url}
                                                data-cy={`brief-summary-${mediaKind}`}
                                            />
                                        </GridColumn>
                                    );
                                })}
                            </Grid>
                        </BriefSummaryCard>
                    )}
                    <BriefSummaryCard title={t("component.briefSummary.context")} data-cy="brief-summary-context">
                        {isLoading ? <TextLoader /> : <Markdown markdown={context} />}
                    </BriefSummaryCard>
                    <BriefSummaryCard
                        title={t("component.briefSummary.pedagogicModalities")}
                        data-cy="brief-summary-pedagogicModalities"
                    >
                        {isLoading ? <TextLoader /> : <Markdown markdown={pedagogicModalities} />}
                    </BriefSummaryCard>
                    {evaluationModalities && (
                        <BriefSummaryCard
                            title={t("component.briefSummary.evaluationModalities")}
                            data-cy="brief-summary-evaluationModalities"
                        >
                            {isLoading ? <TextLoader /> : <Text preserve>{evaluationModalities}</Text>}
                        </BriefSummaryCard>
                    )}
                    <BriefSummaryCard
                        title={t("component.briefSummary.expectedWork")}
                        data-cy="brief-summary-expectedWork"
                    >
                        {isLoading ? <TextLoader /> : <Text preserve>{expectedWork}</Text>}
                    </BriefSummaryCard>
                    {performanceCriteria && (
                        <BriefSummaryCard
                            title={t("component.briefSummary.performanceCriteria")}
                            data-cy="brief-summary-performanceCriteria"
                        >
                            {isLoading ? <TextLoader /> : <Text preserve>{performanceCriteria}</Text>}
                        </BriefSummaryCard>
                    )}
                </div>
                <div className="relative col-span-4 h-full">
                    <div className="absolute right-0 top-0 flex max-h-full flex-col gap-4 overflow-hidden">
                        {btnDraftAside}
                        <BriefSummaryCard title={t("component.briefSummary.professionalSituation")}>
                            <ProfessionalSituation>
                                {professionalSituation?.title && (
                                    <Title as="h3" variant="h4" lines={2}>
                                        {professionalSituation?.title}
                                    </Title>
                                )}

                                {professionalSituation?.problematic ? (
                                    <>
                                        <Text as="h4" variant="label">
                                            {" "}
                                            {t("component.briefSummary.problematic")}
                                        </Text>
                                        <Problematic lines={3}>{professionalSituation?.problematic}</Problematic>
                                    </>
                                ) : (
                                    <Text variant="label"> {t("component.briefSummary.problematic")}</Text>
                                )}

                                <Tag>
                                    {t("component.briefSummary.targetedSkills", {
                                        count: professionalSituation?.skills?.length ?? 0,
                                    })}
                                    <Skill size={16} />
                                </Tag>
                            </ProfessionalSituation>
                        </BriefSummaryCard>
                        <BriefSummaryCard
                            title={t("component.briefSummary.skills")}
                            className="max-h-full flex-1 overflow-auto"
                        >
                            {isLoading
                                ? [1, 2, 3].map(item => <SkillSkeleton key={item} />)
                                : skills &&
                                  skills.length > 0 && (
                                      <SkillsContainer>
                                          {skills
                                              .slice(0, showAllSkills ? skills.length : DEFAULT_SKILLS_TO_SHOW)
                                              .map(({ skill, title, levels }) => (
                                                  <SkillValidation key={skill} title={title} levels={levels} />
                                              ))}
                                          {/* TODO: Handle expansion animation */}
                                          {skills.length - DEFAULT_SKILLS_TO_SHOW > 0 && (
                                              <MoreSkillsActionContainer>
                                                  <Text variant="subheading" aria-hidden={true}>
                                                      {showAllSkills ? "-" : "+"}{" "}
                                                      {t("component.briefSummary.moreSkills", {
                                                          count: skills.length - DEFAULT_SKILLS_TO_SHOW,
                                                      })}
                                                  </Text>
                                                  <IconButton
                                                      size="small"
                                                      variant="secondary"
                                                      onClick={() => setShowAllSkills(!showAllSkills)}
                                                  >
                                                      <Chevron direction={showAllSkills ? "up" : "down"} />
                                                      <SROnly>
                                                          {showAllSkills
                                                              ? t("component.briefSummary.showLessSkills", {
                                                                    count: DEFAULT_SKILLS_TO_SHOW,
                                                                })
                                                              : t("component.briefSummary.showMoreSkills")}
                                                      </SROnly>
                                                  </IconButton>
                                              </MoreSkillsActionContainer>
                                          )}
                                      </SkillsContainer>
                                  )}
                        </BriefSummaryCard>
                    </div>
                    {displaySubmit && (
                        <Button data-cy="edit-brief-save-button" type="submit" variant="success" outlined>
                            {t("form.addBrief.buttons.save")}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default styled(BriefSummary)`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${IconButton} {
        width: 24px;
        height: 24px;

        --icon-color: ${({ theme }) => theme.palette.black.base};
    }
`;
