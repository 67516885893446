import React from "react";
import styled from "styled-components";

import { Text } from "../../uikit";
import SkillLevelChip, { SkillLevelChipStatus } from "../SkillLevelChip";

export type SkillValidationItem = {
    id: string;
    level: number;
    status?: SkillLevelChipStatus;
};

type Props = {
    className?: string;
    title: string;
    levels: SkillValidationItem[];
};

const SkillValidationTitle = styled(Text)`
    padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[5]};
`;

const SkillValidationList = styled.ol<{ count: number }>`
    display: flex;

    padding: 0;
    margin: 0;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    overflow: hidden;
`;

const SkillLevelListItem = styled.li<{ count: number; status: SkillLevelChipStatus | undefined }>`
    list-style: none;
    flex: 1;
    min-width: 0;
    &:nth-child(2),
    &:nth-child(3) {
        margin-left: ${({ status, theme }) => (!status ? theme.spacing[3] : 0)};
    }

    &:nth-child(${({ count }) => count}) > ${SkillLevelChip} {
        border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }

    > ${SkillLevelChip} {
        border-radius: ${({ status, theme }) => (!status ? theme.shape.borderRadius.medium : "0")};
    }
`;

const SkillValidation = ({ className, title, levels }: Props) => {
    return (
        <li className={className} data-cy="skill-validation">
            <SkillValidationTitle>{title}</SkillValidationTitle>
            <SkillValidationList count={levels.filter(level => level.status).length}>
                {levels.map(level => (
                    <SkillLevelListItem
                        key={level.id}
                        status={level.status}
                        count={levels.filter(level => level.status).length}
                    >
                        <SkillLevelChip level={level.level} status={level.status} />
                    </SkillLevelListItem>
                ))}
            </SkillValidationList>
        </li>
    );
};

export default styled(SkillValidation)`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;
