import React, { SyntheticEvent } from "react";
import { Text } from "src/uikit";
import styled from "styled-components";

const LocaleText = styled(Text)`
    text-transform: uppercase;
`;

const CountryFlag = styled.img`
    height: 24px;
    width: 24px;
    margin-left: ${({ theme }) => theme.spacing[2]};
`;

type Props = {
    locale: string;
    className?: string;
};

const LanguageBadge = ({ className, locale }: Props) => (
    <div className={className}>
        <LocaleText variant="label" as="p">
            {locale}
        </LocaleText>
        <CountryFlag
            alt=""
            src={`/static/flags/${locale.substring(0, 2)}.svg`}
            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => (e.currentTarget.style.display = "none")}
        />
    </div>
);

export default styled(LanguageBadge)`
    display: flex;
    align-items: center;
`;
