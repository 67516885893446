/* eslint-disable no-param-reassign */
import * as htmlparser from "htmlparser2";
import md from "markdown-it";
import jsx from "markdown-it-jsx";
import React from "react";

import { underline } from "./underline";

function fixClass(element) {
    if (!element.attribs.class) {
        return;
    }
    element.attribs.className = element.attribs.class;
    delete element.attribs.class;
}

// remove the style attribute and apply it after component mount
function fixStyle(element) {
    if (!element.attribs.style) {
        return;
    }
    const { style } = element.attribs;
    delete element.attribs.style;
    element.attribs.ref = c => {
        if (c) {
            c.setAttribute("style", style);
        }
    };
}

class MarkdownConverter {
    constructor(options) {
        const components = {};
        const blockComponents = {};
        const componentsTags = [];

        if (options && options.components) {
            if (Array.isArray(options.components)) {
                for (let i = 0; i < options.components.length; i += 1) {
                    const component = options.components[i];
                    components[component.name] = component.component;
                    if (component.block) {
                        blockComponents[component.name] = component.component;
                        componentsTags.push(component.name);
                    }
                }
            } else {
                Object.assign(components, options.components);
            }
        }

        this.components = components;

        const parser = md({
            highlight(str) {
                return `${parser.utils.escapeHtml(str)}`;
            },
        });
        parser.use(jsx);
        parser.use(underline);
        this.converter = { makeHtml: markdown => parser.render(markdown) };
    }

    selfClosingTagsExpand(html) {
        const JsxSelfClosingTags = Object.keys(this.components);
        const JsxSelfClosingRegex = new RegExp(`<((${JsxSelfClosingTags.join("|")})[^>]+?)/>`, "gm");
        return html.replace(JsxSelfClosingRegex, "<$1></$2>");
    }

    convert(markdown) {
        let html = this.converter.makeHtml(markdown);
        html = this.selfClosingTagsExpand(html);
        const root = htmlparser.parseDOM(html, {
            // make sure to decode entities to avoid security issue, see
            // https://github.com/fb55/htmlparser2/issues/105
            decodeEntities: true,
            // keep case of attribute names so that stuff like `className` works correctly
            lowerCaseAttributeNames: false,
            // don't alter the case of tags
            lowerCaseTags: false,
        });
        const reactElements = this.mapElements(root);
        // return React.createElement('code', null, [JSON.stringify(this.converter.getAllExtensions(), null, 2)])
        // return React.createElement('code', null, [html])
        if (reactElements && reactElements.length === 1) {
            return reactElements[0];
        }
        return React.createElement("div", null, reactElements);
    }

    mapElements(elements) {
        const children = React.Children.toArray(elements.map(element => this.mapElement(element)).filter(e => e));
        return children.length === 0 ? null : children;
    }

    mapElement(element) {
        if (element.type === "tag") {
            fixStyle(element);
            fixClass(element);

            // this is used to let showdown know whether or not to render md inside of
            // an html element - we want to strip this out before we go to react to
            // avoid unknown prop warnings
            delete element.attribs.markdown;

            // Prevent xss from link href based on protocol whitelist
            const href = element.attribs.href;
            if (href && !["http:", "https:", "ftp:"].includes(new URL(href).protocol)) {
                delete element.attribs.href;
            }

            const component = this.components[element.name];

            if (!component) {
                return null;
            }

            return React.createElement(component, element.attribs, this.mapElements(element.children));
        }
        if (element.type === "text") {
            // Remove whitespaces between tags
            // return element.data.trim().length > 0 ? element.data : null;
            return element.data;
        }
        if (element.type === "comment") {
            // noop
            return null;
        }
        // eslint-disable-next-line no-console
        console.warn(`Warning: Could not map element with type ${element.type} yet.`);
        return null;
    }
}

MarkdownConverter.displayName = "MarkdownConverter";

export default MarkdownConverter;
