import React, { ComponentProps, FC } from "react";
import { Text } from "src/uikit";
import styled from "styled-components";
import { Merge } from "type-fest";

const Title = styled(Text)`
    margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

type Props = Merge<
    {
        title: string;
        className?: string;
    },
    ComponentProps<"div">
>;

const BriefSummaryCard: FC<Props> = ({ title, children, ...props }) => (
    <div {...props}>
        <Title variant="h4" as="h2">
            {title}
        </Title>
        {children}
    </div>
);

export default styled(BriefSummaryCard)`
    padding: ${({ theme }) => theme.spacing[7]};
    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;
