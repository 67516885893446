import get from "lodash/get";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Check, Cross, SvgIcon } from "@sol/icons";

import { Text, SROnly } from "../uikit";

export enum SkillLevelChipStatus {
    VALIDATED = "validated",
    UNVALIDATED = "unvalidated",
    NOT_EVALUATED = "notEvaluated",
}

type Props = {
    level: number;
    status?: SkillLevelChipStatus;
    className?: string;
    hideIcon?: boolean;
};

const statusInformations = {
    [SkillLevelChipStatus.VALIDATED]: {
        message: "accessibility.corrections.skillLevels.valid",
        icon: <Check />,
    },
    [SkillLevelChipStatus.UNVALIDATED]: {
        message: "accessibility.corrections.skillLevels.invalid",
        icon: <Cross />,
    },
    [SkillLevelChipStatus.NOT_EVALUATED]: {
        message: "accessibility.corrections.skillLevels.notEvaluated",
        icon: null,
    },
};

const STATUS_TO_COLOR = {
    [SkillLevelChipStatus.UNVALIDATED]: "red.base",
    [SkillLevelChipStatus.VALIDATED]: "green.base",
    [SkillLevelChipStatus.NOT_EVALUATED]: "grey.lightest",
};

const SkillLevelChip = ({ level, status, className, hideIcon }: Props) => {
    const [t] = useTranslation();

    const statusInfos = status && statusInformations[status];

    return (
        <span className={className}>
            <Text
                variant="subheading"
                as="span"
                textAlign="center"
                lines={1}
                title={t(`component.skillLevelChip.level${level}`)}
            >
                {t(`component.skillLevelChip.level${level}`)}
                {!hideIcon && statusInfos?.message && <SROnly>{t(statusInfos.message)}</SROnly>}
            </Text>
            {!hideIcon && statusInfos?.icon}
        </span>
    );
};

SkillLevelChip.defaultProps = {
    status: SkillLevelChipStatus.NOT_EVALUATED,
};

export default styled(SkillLevelChip)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[5]}`};
    margin: 0;

    background-color: ${({ status, theme }) =>
        get(theme.palette, STATUS_TO_COLOR[status || SkillLevelChipStatus.NOT_EVALUATED]) as string};

    ${SvgIcon} {
        --icon-color: ${({ theme }) => theme.palette.white.base};
        height: 16px;
    }

    ${Text} {
        min-width: 0;
        color: ${({ theme, status }) =>
            !status || status === SkillLevelChipStatus.NOT_EVALUATED
                ? theme.palette.grey.base
                : theme.palette.white.base};
    }
`;
