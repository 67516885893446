import isUndefined from "lodash/isUndefined";
import MarkdownIt, { StateInline } from "markdown-it";

// ++underline++
//

const TAG = "u";
const DELIMITER = "+";
const ASCII_DELIMITER = DELIMITER.charCodeAt(0);

// Insert each marker as a separate text token, and add it to delimiter list
//
function tokenizeRule(state: StateInline, silent: boolean) {
    const start = state.pos;
    const marker = state.src.charCodeAt(start);

    if (silent) {
        return false;
    }

    if (marker !== ASCII_DELIMITER) {
        return false;
    }

    const scanned = state.scanDelims(state.pos, true);
    let len = scanned.length;
    const ch = String.fromCharCode(marker);

    if (len < 2) {
        return false;
    }

    let token;

    if (len % 2) {
        // Odd markers
        token = state.push("text", "", 0);
        token.content = ch;
        len--;
    }

    for (let i = 0; i < len; i += 2) {
        token = state.push("text", "", 0);
        token.content = `${ch}${ch}`;

        state.delimiters.push({
            marker,
            length: 0, // disable "rule of 3" length checks meant for emphasis
            token: state.tokens.length - 1,
            end: -1,
            open: scanned.can_open,
            close: scanned.can_close,
            jump: 0, // TODO: check validity
        });
    }

    state.pos += scanned.length;

    return true;
}

function postProcess(state: StateInline, delimiters: StateInline.Delimiter[]) {
    let token;
    const loneMarkers = [];
    const max = delimiters.length;

    for (let i = 0; i < max; i++) {
        const startDelim = delimiters[i];

        if (startDelim.marker !== ASCII_DELIMITER) {
            continue;
        }

        if (startDelim.end === -1) {
            continue;
        }

        const endDelim = delimiters[startDelim.end];

        const createToken = (isClosing: boolean) => {
            const token = new state.Token(`${TAG}_${isClosing ? "close" : "open"}`, TAG, isClosing ? -1 : 1);

            token.markup = `${DELIMITER}${DELIMITER}`;
            token.content = "";

            return token;
        };

        state.tokens[startDelim.token] = createToken(false);
        state.tokens[endDelim.token] = createToken(true);

        const token = state.tokens[endDelim.token - 1];

        if (token && token.type === "text" && token.content === DELIMITER) {
            loneMarkers.push(endDelim.token - 1);
        }
    }

    // If a marker sequence has an odd number of characters, it's splitted
    // like this: `~~~~~` -> `~` + `~~` + `~~`, leaving one marker at the
    // start of the sequence.
    //
    // So, we have to move all those markers after subsequent s_close tags.
    //
    while (loneMarkers.length) {
        const i = loneMarkers.pop();
        if (isUndefined(i)) {
            continue;
        }
        let j = i + 1;

        while (j < state.tokens.length && state.tokens[j].type === `${TAG}_close`) {
            j++;
        }

        j--;

        if (i !== j) {
            token = state.tokens[j];
            state.tokens[j] = state.tokens[i];
            state.tokens[i] = token;
        }
    }
}

// Walk through delimiter list and replace text tokens with tags
//
function postProcessRule(state: StateInline) {
    const tokens_meta = state.tokens_meta;
    const max = state.tokens_meta.length;

    postProcess(state, state.delimiters);

    for (let curr = 0; curr < max; curr++) {
        const delimiters = tokens_meta[curr]?.delimiters;
        if (delimiters) {
            postProcess(state, delimiters);
        }
    }

    return true;
}

export function underline(md: MarkdownIt) {
    md.inline.ruler.before("emphasis", "underline", tokenizeRule);
    md.inline.ruler2.before("emphasis", "underline", postProcessRule);
}
